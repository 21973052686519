import { FC } from 'react';
import dynamic from 'next/dynamic';
import { useSearchParams } from 'next/navigation';
import Bowser from 'bowser';
import { isEmpty } from 'radash';
import { IHomeCampaign } from '@modules/home/domain/models/campaign.model';
import { IGameBanner } from '@modules/home/domain/models/gameBanner.model';
import { ITrend } from '@modules/home/domain/models/trendsForYou.model';
import PointButtonLogic from '@components/point/PointButton.logic';
import { getUtmParams } from '@shared/helpers/getUtmParams.helper';
import { convertDate } from '@shared/utils/convertDate.utils';
import { useWindowSize } from '@shared/utils/hooks';
import { useUtag } from '@shared/utils/hooks/useUtag.utils';
import breakpoint from '../../../../shared/assets/breakpoint.json';
import CpTownButton from './button/CpTownButton';
import SideButton from './button/SideButton';
import { CampaignContainer, CampaignImage, Container, ContainerRightButton, ContainerWrapper, GridBoxDesktop, ShadowLineButtom, ShadowLineLeft, ShadowLineRight, VideoBanner, WrapperCpTownButton, WrapperSecondSideButton } from './HomeBanner.style';
const TrendButton = dynamic(() => import('./button/TrendButton'));
interface Props {
  homeCampaign: IHomeCampaign;
  trendsForYou: ITrend[];
  gameBanner: IGameBanner;
  isOpenTrendForyou: boolean;
  setIsOpenTrendForyou: (val: boolean) => void;
  activeTrends: ITrend | undefined;
  setActiveTrends: (val: ITrend | undefined) => void;
  onClosePopover: () => void;
}
const HomeBanner: FC<Props> = ({
  trendsForYou,
  homeCampaign,
  gameBanner,
  isOpenTrendForyou,
  setIsOpenTrendForyou,
  activeTrends,
  setActiveTrends,
  onClosePopover
}: Props) => {
  const utag = useUtag();
  const {
    width
  } = useWindowSize();
  const browserType = Bowser.getParser(window.navigator.userAgent);
  const isMobile = width < breakpoint.lg - 1;
  const searchParams = useSearchParams();
  const defualtDailyLoginUtm = {
    campaign: 'CPBrandsite',
    source: 'Website_Direct',
    medium: 'DailyLogin'
  };
  const defualtMinigameUtm = {
    campaign: 'CPBrandsite',
    source: 'Website_Direct',
    medium: 'Minigame'
  };
  const dailyLoginLinkout = process.env.GAME_FRONTEND_DOMAIN + '?state=dailyLogin&' + getUtmParams(searchParams, defualtDailyLoginUtm);
  const minigameLinkout = process.env.GAME_FRONTEND_DOMAIN + '?state=minigame&' + getUtmParams(searchParams, defualtMinigameUtm);
  const isGameReady = true;
  const renderGameBannerDesktop = () => {
    return <VideoBanner preload="auto" playsInline autoPlay muted loop>
                {gameBanner.bannerDesktop && <source src={gameBanner.bannerDesktop} type="video/webm" />}
            </VideoBanner>;
  };
  const renderGameBannerMobile = () => {
    return isAndroid ? <VideoBanner preload="auto" playsInline autoPlay muted loop>
                {gameBanner.bannerMobile && <source src={gameBanner.bannerMobile} type="video/webm" />}
            </VideoBanner> : <VideoBanner preload="auto" playsInline autoPlay muted loop>
                {gameBanner.bannerIos && <source src={gameBanner.bannerIos} type="video/mp4" />}
            </VideoBanner>;
  };
  const browserList = {
    chrome: '>0',
    firefox: '>0',
    opera: '>0',
    edge: '>0',
    ie: '>0'
  };
  const isAndroid = browserType.satisfies({
    desktop: browserList,
    tablet: {
      android: browserList
    },
    mobile: {
      android: browserList
    }
  });
  return <ContainerWrapper>
            <GridBoxDesktop onClick={() => onClosePopover()} />
            <Container>
                {!isEmpty(trendsForYou) && <TrendButton trends={trendsForYou} isOpen={isOpenTrendForyou} setIsOpen={setIsOpenTrendForyou} activeTrends={activeTrends} setActiveTrends={setActiveTrends} onClosePopover={onClosePopover} />}
                <div onClick={() => onClosePopover()}>
                    <ShadowLineLeft onClick={() => onClosePopover()} />
                    <ShadowLineRight onClick={() => onClosePopover()} />
                    <ShadowLineButtom onClick={() => onClosePopover()} />
                    {isMobile ? renderGameBannerMobile() : renderGameBannerDesktop()}

                    {isGameReady && <ContainerRightButton>
                            <PointButtonLogic size="large" />
                            <WrapperSecondSideButton>
                                <SideButton title="DAILY LOGIN" href={dailyLoginLinkout} src="/images/home/banner/daily-login-icon.png" onClick={() => utag.link({
              tealium_event: 'daily_login_click'
            })} />
                            </WrapperSecondSideButton>

                            <SideButton title="MINI GAME" href={minigameLinkout} src="/images/home/banner/mini-game-icon.png" onClick={() => utag.link({
            tealium_event: 'mini_game_click',
            date_time: convertDate(Date.now(), 'en', 'DD MMM YYYY HH:mm:ss')
          })} />
                        </ContainerRightButton>}

                    {homeCampaign && <CampaignContainer href={homeCampaign.linkoutUrl} target="_blank" onClick={() => utag.link({
          tealium_event: 'campaign_click',
          campaign_name: homeCampaign.title
        })} aria-label="campaign">
                            {homeCampaign.thumbUrl && <CampaignImage src={homeCampaign.thumbUrl} width={160} height={160} alt="campaign" />}
                            <span>{homeCampaign.description}</span>
                        </CampaignContainer>}
                    {isGameReady && <WrapperCpTownButton>
                            <CpTownButton />
                        </WrapperCpTownButton>}
                </div>
            </Container>
            <GridBoxDesktop onClick={() => onClosePopover()} />
        </ContainerWrapper>;
};
export default HomeBanner;