import { FC, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import { useAnimation } from 'framer-motion';
import { getUtmParams } from '@shared/helpers/getUtmParams.helper';
import { convertDate } from '@shared/utils/convertDate.utils';
import { useWindowSize } from '@shared/utils/hooks';
import { useUtag } from '@shared/utils/hooks/useUtag.utils';
import breakpoint from '../../../../../shared/assets/breakpoint.json';
import { Container, Title, WrapperIcon, WrapperLinkout } from './CpTownButton.style';
const CpTownButton: FC = () => {
  const utag = useUtag();
  const searchParams = useSearchParams();
  const {
    width
  } = useWindowSize();
  const containerAnimationControl = useAnimation();
  const titleAnimationControls = useAnimation();
  const iconAnimationControls = useAnimation();
  const [isPlayingForward, setIsPlayingForward] = useState(false);
  const [isPlayingBackward, setIsPlayingBackward] = useState(false);
  const [isMouseHover, setIsMouseHover] = useState(false);
  const isMobile = width < breakpoint.lg;
  const defualtUtmParams = {
    campaign: 'CPBrandsite',
    source: 'Website_Direct',
    medium: 'IntoCPGame'
  };
  const linkout = process.env.GAME_FRONTEND_DOMAIN + '/?' + getUtmParams(searchParams, defualtUtmParams);
  const animationTranstion = (bounce: number = 0.5, duration: number = 0.9) => {
    return {
      duration,
      type: 'spring',
      bounce
    };
  };
  const containerAnimate = {
    init: {
      background: 'linear-gradient( 180deg,#ce0e2d 5.68%, #dd162f 60.23%, #ff2833 112.5%)',
      transition: animationTranstion()
    },
    forward: {
      background: 'linear-gradient(180deg, #F53C04 5.68%, #F58712 60.23%, #F5B91C 112.5%)',
      transition: animationTranstion(0)
    }
  };
  const titleAnimate = {
    init: {
      x: '0%',
      transition: animationTranstion()
    },
    forward: {
      x: '30%',
      transition: animationTranstion()
    }
  };
  const iconAnimate = {
    init: {
      x: '0%',
      transition: animationTranstion()
    },
    forward: {
      x: width < breakpoint.lg ? '-400%' : '-480%',
      transition: animationTranstion(0.4)
    }
  };
  return <Container initial={{
    background: 'linear-gradient( 180deg,#ce0e2d 5.68%, #dd162f 60.23%, #ff2833 112.5%)'
  }} animate={isMobile ? 'none' : containerAnimationControl} onHoverStart={() => {
    if (!isPlayingForward && !isPlayingBackward) {
      containerAnimationControl.start(containerAnimate.forward);
      titleAnimationControls.start(titleAnimate.forward);
      iconAnimationControls.start(iconAnimate.forward);
      setIsPlayingForward(true);
    }
    setIsMouseHover(true);
  }} onHoverEnd={() => {
    if (!isPlayingForward && !isPlayingBackward) {
      containerAnimationControl.start(containerAnimate.init);
      titleAnimationControls.start(titleAnimate.init);
      iconAnimationControls.start(iconAnimate.init);
      setIsPlayingBackward(true);
    }
    setIsMouseHover(false);
  }} onAnimationComplete={(definition: any) => {
    const isForwardComplete = definition.background == containerAnimate.forward.background;
    const isBackwardComplete = definition.background == containerAnimate.init.background;
    if (isForwardComplete) {
      setIsPlayingForward(false);
      if (!isMouseHover) {
        setTimeout(() => {
          containerAnimationControl.start(containerAnimate.init);
          titleAnimationControls.start(titleAnimate.init);
          iconAnimationControls.start(iconAnimate.init);
          setIsPlayingBackward(true);
        }, 100);
      }
    } else if (isBackwardComplete) {
      setIsPlayingBackward(false);
    }
  }} onClick={() => {
    utag.link({
      tealium_event: 'cp_town_button_click',
      date_time: convertDate(Date.now(), 'en', 'DD MMM YYYY HH:mm:ss')
    });
  }}>
            <WrapperLinkout href={linkout} target="_blank" aria-label="cp town" rel="preconnect">
                <Title animate={isMobile ? 'none' : titleAnimationControls}>
                    เข้าสู่ CP City
                </Title>

                <WrapperIcon animate={isMobile ? 'none' : iconAnimationControls}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                        <path d="M16.5 0C25.3368 0 32.5 7.1632 32.5 16C32.5 24.8368 25.3368 32 16.5 32C7.6632 32 0.5 24.8368 0.5 16C0.5 7.1632 7.6632 0 16.5 0ZM11.6424 10.64L11.5576 11.44L11.5096 11.9728L11.4424 12.9328L11.404 13.6688L11.3752 14.4768L11.3592 15.3552V16.28L11.3752 17.1584L11.404 17.968L11.4424 18.704L11.5096 19.664L11.6232 20.832L11.644 20.9952C11.6794 21.2891 11.7827 21.5707 11.9457 21.8177C12.1087 22.0648 12.327 22.2705 12.5833 22.4186C12.8396 22.5667 13.1269 22.6531 13.4223 22.6709C13.7178 22.6888 14.0133 22.6377 14.2856 22.5216L15.0152 22.1984L16.0568 21.704L16.6808 21.3904L17.3688 21.032L18.1144 20.6272L18.9112 20.176L19.7 19.712L20.0696 19.488L20.7576 19.056L21.3784 18.656L21.9256 18.2896L22.9704 17.5536L23.2536 17.344C23.4909 17.1663 23.6835 16.9357 23.8162 16.6705C23.9488 16.4053 24.0178 16.1129 24.0176 15.8164C24.0174 15.5199 23.9482 15.2275 23.8153 14.9625C23.6824 14.6975 23.4895 14.4671 23.252 14.2896L22.6024 13.816L22.1624 13.5072L21.6504 13.1584L20.7464 12.5696L20.0616 12.1408L19.3096 11.6896L18.5112 11.2288L17.7464 10.8048L17.0312 10.424L16.3752 10.088L15.5112 9.6672L14.8088 9.344L14.4408 9.1808C14.3902 9.15931 14.3395 9.13798 14.2888 9.1168C14.0165 8.99979 13.7207 8.94785 13.4249 8.96508C13.129 8.98232 12.8412 9.06827 12.5844 9.2161C12.3275 9.36394 12.1087 9.56959 11.9452 9.81676C11.7817 10.0639 11.678 10.3458 11.6424 10.64Z" fill="white" />
                    </svg>
                </WrapperIcon>
            </WrapperLinkout>
        </Container>;
};
export default CpTownButton;